import { Form, Modal, notification } from "antd";
import { useCallback, useState } from "react";
import { useSalarySlipContext } from "../../components/SalarySlip/context";
import { SalarySlipForm } from "../../components/SalarySlip/Form";
import axios from "../../helper/axios_config";

export const AddSalarySlip = ({ openAddNew, toggleAddNew }) => {
   const [loading, setLoading] = useState(false);
   const [form] = Form.useForm();

   const { refresh } = useSalarySlipContext();

   const handleAddNew = useCallback(async () => {
      try {
         const values = await form.validateFields();
         try {
            setLoading(true);
            const {
               data: { error },
            } = await axios.post("/salary_slip/create", values);
            if (error) notification.error({ message: error });
            else {
               notification.success({ message: "Thêm bảng lương thành công!" });
               toggleAddNew?.((prev) => !prev);
               refresh?.();
            }
         } catch (error) {
            if (!error) return;
            const { message } = error;
            if (message) notification.error({ message });
         }
         setLoading(false);
      } catch (error) {}
   }, [form, toggleAddNew, refresh]);

   return (
      <Modal
         title="Thêm Bảng Lương"
         centered
         open={openAddNew}
         onOk={handleAddNew}
         onCancel={() => toggleAddNew((prev) => !prev)}
         width={1000}
         confirmLoading={loading}
      >
         <Form layout="vertical" form={form}>
            <SalarySlipForm inputForm={form} />
         </Form>
      </Modal>
   );
};
