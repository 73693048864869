import React from "react";
import { Menu, Layout } from "antd";
import {
   CalendarOutlined,
   CheckSquareOutlined,
   HomeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Sider } = Layout;

const menu = [
   { title: "Trang chủ", icon: HomeOutlined, path: "/" },
   { title: "Bảng lương", icon: CalendarOutlined, path: "/salary-slip" },
   { title: "Bảng công", icon: CheckSquareOutlined, path: "/timesheet" },
].map((item, index) => {
   const key = String(index + 1);
   return {
      key: `sub${key}`,
      icon: React.createElement(item.icon),
      label: <Link to={item.path}>{item.title}</Link>,
   };
});

export const Sidebar = () => {
   return (
      <Sider
         width={200}
         className="site-layout-background"
         style={{
            marginTop: 56,
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
         }}
      >
         <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{
               height: "100%",
               borderRight: 0,
               background: "#f4f6f8",
               borderRight: "0.1rem solid #dfe3e8",
               maxWidth: "24rem",
            }}
            items={menu}
         />
      </Sider>
   );
};
