import { SendOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import { useCallback, useState } from "react";

import axios from "../../helper/axios_config";

export const SendTimeSheet = ({ isDisable, btnName, isSendAll, timeSheet }) => {
   const [loading, setLoading] = useState(false);

   const handleSend = useCallback(async () => {
      try {
         setLoading(true);
         let input = {
            ids: [timeSheet["id"]],
            isSendAll: false,
         };
         if (isSendAll) {
            input = {
               ids: [],
               isSendAll: true,
            };
         }
         const {
            data: { error },
         } = await axios.post(`/timesheet/send`, input);
         if (error) notification.error({ message: error });
         else {
            notification.success({
               message: `Gửi bảng công thành công!`,
            });
         }
      } catch (error) {
         if (!error) return;
         const { message } = error;
         if (message) notification.error({ message });
      }
      setLoading(false);
   }, [isSendAll, timeSheet]);

   return (
      <Button
         disabled={isDisable}
         type="primary"
         icon={<SendOutlined />}
         children={btnName}
         loading={loading}
         onClick={handleSend}
      />
   );
};
