import { Button, Row, Typography } from "antd";
import { Fragment, useCallback, useRef, useState } from "react";
import CSVReader from "react-csv-reader";
import { CloudUploadOutlined, PaperClipOutlined } from "@ant-design/icons";

const { Text } = Typography;

const papaparseOptions = {
   header: true,
   dynamicTyping: true,
   skipEmptyLines: true,
   transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

export const ImportCsv = ({ onChange }) => {
   const [fileName, setFileName] = useState(null);
   const clickUpFileRef = useRef(null);

   const handleClickUploadFile = useCallback(() => {
      clickUpFileRef.current.click();
   }, [clickUpFileRef]);

   const handleForce = useCallback(
      (data, fileInfo) => {
         const { name } = fileInfo;
         setFileName(name);
         onChange?.(data, fileInfo);
      },
      [onChange]
   );

   return (
      <Fragment>
         <CSVReader
            label={
               <div className="csv-upload" onClick={handleClickUploadFile}>
                  <Button
                     type="primary"
                     icon={<CloudUploadOutlined />}
                     children="Click to upload"
                  ></Button>
                  {fileName && (
                     <Row style={{ marginTop: 5 }} justify="center">
                        <Text>
                           {<PaperClipOutlined style={{ marginRight: 5 }} />}
                           {fileName}
                        </Text>
                     </Row>
                  )}
               </div>
            }
            onFileLoaded={handleForce}
            parserOptions={papaparseOptions}
            inputRef={(ref) => (clickUpFileRef.current = ref)}
            inputStyle={{ display: "none" }}
         />
      </Fragment>
   );
};
