import React, { useContext, useMemo } from "react";

const SalarySlipContext = React.createContext();

export const SalarySlipProvider = ({ children, ...props }) => {
   const bag = useMemo(() => ({ ...props }), [props]);
   return (
      <SalarySlipContext.Provider value={bag}>
         {children}
      </SalarySlipContext.Provider>
   );
};

export const useSalarySlipContext = () => {
   return useContext(SalarySlipContext);
};
