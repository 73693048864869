import { Menu, Layout, Row, Avatar, Space, Typography } from "antd";
import logo from "../assets/images/logo.png";
import { useAppCtx } from "../components/App/context";
const { Header } = Layout;
const { Text } = Typography;

export const OMGHeader = () => {
   const {
      value: { user },
   } = useAppCtx();

   return (
      <Header
         style={{
            height: 56,
            position: "fixed",
            zIndex: 1,
            width: "100%",
            padding: "0 10px",
            backgroundColor: "#fff",
            boxShadow: "0 1px 5px 0 rgb(41 85 115 / 21%)",
            lineHeight: 0,
         }}
      >
         <Row justify="space-between" align="middle" style={{ height: 56 }}>
            <Row align="middle">
               <img src={`${logo}`} style={{ width: 180 }} />
            </Row>

            <Space>
               <Avatar
                  style={{
                     backgroundColor: "#f56a00",
                     verticalAlign: "middle",
                  }}
                  size="medium"
                  children={user["fullName"].charAt(0).toUpperCase()}
               />
               <Text>{user["fullName"]}</Text>
            </Space>
         </Row>
      </Header>
   );
};
