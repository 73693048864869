import React, { useCallback, useEffect, useState } from "react";
import { Layout, Breadcrumb, Skeleton } from "antd";
import "antd/dist/antd.css";

import "./assets/css/index.css";
import { OMGHeader } from "./layouts/Header";
import { Sidebar } from "./layouts/Sidebar";
import { SalarySlipPage } from "./page/salaryslip/SalarySlips";
import { AppProvider } from "./components/App/context";
import { Login } from "./login";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Home } from "./page/Home";
import axios from "./helper/axios_config";
import { TimesheetPage } from "./page/timesheets/Timesheets";

const App = () => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(true);
   const auth = useCallback(async () => {
      try {
         const {
            data: { user },
         } = await axios.get("/user/current_user");
         if (user) setData((prev) => ({ ...prev, user }));
         navigate(user ? "/" : "/login");
      } catch (error) {
         navigate("/login");
      }
      setLoading(false);
   }, []);

   const changeData = useCallback((value) => {
      setData((prev) => ({ ...prev, ...value }));
   }, []);

   const [data, setData] = useState({
      user: null,
      change: changeData,
   });

   useEffect(() => {
      auth();
   }, [auth]);

   return (
      <AppProvider value={data}>
         {loading ? (
            <Skeleton />
         ) : data.user ? (
            <Layout>
               <OMGHeader />
               <Layout hasSider>
                  <Sidebar />
                  <Layout
                     className="omg-layout"
                     style={{ marginTop: 56, minHeight: "100vh" }}
                  >
                     <Routes>
                        <Route path="/" element={<Home />} />
                        <Route
                           path="salary-slip"
                           element={<SalarySlipPage />}
                        />
                         <Route
                           path="timesheet"
                           element={<TimesheetPage />}
                        />
                     </Routes>
                  </Layout>
               </Layout>
            </Layout>
         ) : (
            <Routes>
               <Route path="/login" element={<Login />} />
            </Routes>
         )}
      </AppProvider>
   );
};

export default App;
