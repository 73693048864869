import { Button, Modal, notification, Space, Typography } from "antd";
import { Fragment, useCallback, useState } from "react";

import { useSalarySlipContext } from "./context";
import axios from "../../helper/axios_config";
import { ImportCsv } from "../Utils/ImportCsv";

const { Text, Link } = Typography;

export const ImportSalarySlip = () => {
   const [openImport, toggleImport] = useState(false);
   const [importLoading, setImportLoading] = useState(false);
   const [importValues, setImportValue] = useState([]);
   const { refresh } = useSalarySlipContext();

   const handleImport = useCallback(async () => {
      try {
         setImportLoading(true);
         const {
            data: { error },
         } = await axios.post("/salary_slip/import", importValues);
         // if (error) notification.error({ message: error });
         // else {
         notification.success({ message: "Import bảng lương thành công!" });
         toggleImport((prev) => !prev);
         refresh?.();
         // }
      } catch (error) {
         if (!error) return;
         const { message } = error;
         if (message) notification.error({ message });
      }
      setImportLoading(false);
   }, [importValues, refresh]);

   return (
      <Fragment>
         <Button
            key="import"
            type="primary"
            children="Import"
            onClick={() => toggleImport((prev) => !prev)}
         />
         <Modal
            key="model-import"
            title="Import Bảng Lương"
            open={openImport}
            onOk={handleImport}
            onCancel={() => toggleImport((prev) => !prev)}
            confirmLoading={importLoading}
         >
            <ImportCsv onChange={(values) => setImportValue(values)} />
            <Space style={{ marginTop: 20 }}>
               <Text children="Xem file mẫu tại đây:" />
               <Link
                  // href="https://docs.google.com/spreadsheets/d/1HMzTgipSPLBOJYFosMFbOzhrDdJrYPgk2-cp-sWcyPs/edit?usp=sharing"
                  href="https://docs.google.com/spreadsheets/d/1KmNVv-FqYv7zXB4IBGu56rS0CNHiLqxBUONd1pwIuak/edit#gid=1047850432"
                  target="_blank"
                  children="Link"
               />
            </Space>
         </Modal>
      </Fragment>
   );
};
