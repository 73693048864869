import { DeleteOutlined, EditOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Modal, notification, Space } from "antd";
import { useCallback, useState } from "react";

import axios from "../../helper/axios_config";
import { EditSalarySlip } from "../../page/salaryslip/Edit";
import { useSalarySlipContext } from "./context";

export const SalarySlipActions = ({ value }) => {
   const [openEdit, toggleEdit] = useState(false);
   const [openDelete, toggleDelete] = useState(false);
   const [loadingDelete, setLoadingDelete] = useState(false);
   const [loadingSend, setLoadingSend] = useState(false);
   const { refresh } = useSalarySlipContext();

   const handleDelete = useCallback(async () => {
      try {
         setLoadingDelete(true);
         const {
            data: { error },
         } = await axios.delete(`/salary_slip/delete/${value["id"]}`);
         if (error) notification.error({ message: error });
         else {
            notification.success({ message: "Xoá bảng lương thành công!" });
            toggleDelete((prev) => !prev);
            refresh?.();
         }
      } catch (error) {
         if (!error) return;
         const { message } = error;
         if (message) notification.error({ message });
      }
      setLoadingDelete(false);
   }, [refresh, value]);

   const handleSend = useCallback(async () => {
      try {
         setLoadingSend(true);
         const {
            data: { error },
         } = await axios.post(`/salary_slip/send`, {
            ids: [value["id"]],
            isSendAll: false,
         });
         if (error) notification.error({ message: error });
         else {
            notification.success({
               message: `Gửi tới ${value["hoTen"]} thành công!`,
            });
         }
      } catch (error) {
         if (!error) return;
         const { message } = error;
         if (message) notification.error({ message });
      }
      setLoadingSend(false);
   }, [value]);

   return (
      <Space size="middle">
         <Button
            type="link"
            icon={<SendOutlined />}
            onClick={handleSend}
            loading={loadingSend}
         />

         <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => toggleEdit((prev) => !prev)}
         />
         <EditSalarySlip
            openEdit={openEdit}
            toggleEdit={toggleEdit}
            value={value}
         />
         <Button
            type="text"
            danger
            icon={<DeleteOutlined />}
            onClick={() => toggleDelete((prev) => !prev)}
         />
         <Modal
            title="Xoá Bảng Lương"
            centered
            open={openDelete}
            onOk={handleDelete}
            onCancel={() => toggleDelete((prev) => !prev)}
            confirmLoading={loadingDelete}
            children={`Bạn có chắc chắn muốn xoá phiếu lương: ${value.hoTen}`}
         />
      </Space>
   );
};
