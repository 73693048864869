import axios from "axios";
import { getCookie } from ".";
import { API_DOMAIN } from "../config";

const instance = axios.create({
   baseURL: API_DOMAIN,
});
instance.defaults.headers.common["Authorization"] =
   "Bearer " + getCookie("salary_slip_token");
instance.defaults.headers.post["Content-Type"] = "application/json";

instance.interceptors.response.use(
   function (response) {
      return response;
   },
   function (error) {
      return Promise.reject({
         message: error?.response?.data?.error,
         status: error?.response?.status,
      });
   }
);

export default instance;
