import { Button, notification, PageHeader, Typography } from "antd";
import { useCallback, useState } from "react";
import { triggerBase64Download } from "react-base64-downloader";

import { AddSalarySlip } from "../../page/salaryslip/Add";
import { ImportSalarySlip } from "./Import";
import { RefreshSalarySlip } from "./Refresh";
import axios from "../../helper/axios_config";

export const SalarySlipHeader = () => {
   const [openAddNew, toggleAddNew] = useState(false);
   const [loadingSend, setLoadingSend] = useState(false);
   const [loadingExport, setLoadingExport] = useState(false);

   const handleSendAll = useCallback(async () => {
      try {
         setLoadingSend(true);
         const {
            data: { error },
         } = await axios.post(`/salary_slip/send`, {
            ids: [],
            isSendAll: true,
         });
         if (error) notification.error({ message: error });
         else {
            notification.success({
               message: `Gửi phiếu lương thành công!`,
            });
         }
      } catch (error) {
         if (!error) return;
         const { message } = error;
         if (message) notification.error({ message });
      }
      setLoadingSend(false);
   }, []);

   const handleExport = useCallback(async () => {
      try {
         setLoadingExport(true);
         const {
            data: { data, error },
         } = await axios.post(`/salary_slip/export`, {
            ids: [],
            isSendAll: true,
         });
         if (error) notification.error({ message: error });
         else if (!data)
            notification.error({ message: "Export bảng lương thất bại" });
         else {
            // down file
            var a = document.createElement("a");
            a.href = `data:text/csv;base64,${data}`;
            a.download = "salary_slip.csv";
            a.click();

            notification.success({
               message: `Export phiếu lương thành công!`,
            });
         }
      } catch (error) {
         if (!error) return;
         const { message } = error;
         if (message) notification.error({ message });
      }
      setLoadingExport(false);
   }, []);

   return (
      <PageHeader
         style={{ paddingLeft: 0, paddingRight: 0 }}
         extra={[
            <Button
               key="export"
               type="primary"
               children="Export"
               onClick={handleExport}
               loading={loadingExport}
            />,
            <ImportSalarySlip key="import" />,
            <RefreshSalarySlip key="refresh" />,
            <Button
               key="send-all"
               type="primary"
               children="Gửi tất cả"
               onClick={handleSendAll}
               loading={loadingSend}
            />,
            <Button
               key="add-new"
               type="primary"
               children="Thêm bảng lương"
               onClick={() => toggleAddNew((prev) => !prev)}
            />,
            <AddSalarySlip
               key="form-add-new"
               openAddNew={openAddNew}
               toggleAddNew={toggleAddNew}
            />,
         ]}
      />
   );
};
