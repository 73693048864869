import React, { useContext, useMemo } from "react";

const AppContext = React.createContext();

export const AppProvider = ({ children, ...props }) => {
   const bag = useMemo(() => ({ ...props }), [props]);
   return <AppContext.Provider value={bag}>{children}</AppContext.Provider>;
};

export const useAppCtx = () => {
   return useContext(AppContext);
};
