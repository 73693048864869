import { Form, Modal, notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSalarySlipContext } from "../../components/SalarySlip/context";
import { SalarySlipForm } from "../../components/SalarySlip/Form";
import axios from "../../helper/axios_config";

export const EditSalarySlip = ({ openEdit, toggleEdit, value }) => {
   const [loading, setLoading] = useState(false);
   const [form] = Form.useForm();
   const { refresh } = useSalarySlipContext();

   const handleEdit = useCallback(async () => {
      try {
         const values = await form.validateFields();
         try {
            setLoading(true);
            const {
               data: { error },
            } = await axios.put("/salary_slip/update", {
               id: value["id"],
               ...values,
            });
            if (error) notification.error({ message: error });
            else {
               notification.success({
                  message: "Cập Nhật bảng lương thành công!",
               });
               toggleEdit?.((prev) => !prev);
               refresh?.();
            }
         } catch (error) {
            if (!error) return;
            const { message } = error;
            if (message) notification.error({ message });
         }
         setLoading(false);
      } catch (error) {}
   }, [form, refresh, toggleEdit, value]);

   useEffect(() => {
      form.setFieldsValue(value);
   }, [value, form]);

   return (
      <Modal
         title="Cập Nhật Bảng Lương"
         centered
         open={openEdit}
         onOk={handleEdit}
         onCancel={() => toggleEdit((prev) => !prev)}
         width={1000}
         confirmLoading={loading}
      >
         <Form layout="vertical" form={form}>
            <SalarySlipForm inputForm={form} />
         </Form>
      </Modal>
   );
};
