import { Button, notification, Popconfirm } from "antd";
import { useCallback, useState } from "react";

import axios from "../../helper/axios_config";
import { useSalarySlipContext } from "./context";

export const RefreshSalarySlip = () => {
   const [openConfirmRefresh, toggleConfirmRefresh] = useState(false);
   const [refreshLoading, setRefreshLoading] = useState(false);
   const { refresh } = useSalarySlipContext();

   const handelRefresh = useCallback(async () => {
      try {
         setRefreshLoading(true);
         const {
            data: { error },
         } = await axios.post("/salary_slip/reset");
         if (error) notification.error({ message: error });
         else {
            notification.success({ message: "Làm mới bảng lương thành công!" });
            toggleConfirmRefresh((prev) => !prev);
            refresh?.();
         }
      } catch (error) {
         if (!error) return;
         const { message } = error;
         if (message) notification.error({ message });
      }
      setRefreshLoading(false);
   }, [refresh]);

   return (
      <Popconfirm
         key="refresh"
         title="Ban có chắc chắn làm mới không？"
         okText="Ok"
         cancelText="Cancel"
         onConfirm={handelRefresh}
         okButtonProps={{
            loading: refreshLoading,
         }}
         open={openConfirmRefresh}
         onCancel={() => {
            toggleConfirmRefresh((prev) => !prev);
         }}
      >
         <Button
            type="primary"
            children="Làm mới"
            loading={refreshLoading}
            onClick={() => {
               toggleConfirmRefresh((prev) => !prev);
            }}
         />
      </Popconfirm>
   );
};
