import {
   Button,
   Card,
   Col,
   Form,
   Input,
   InputNumber,
   Layout,
   notification,
   Row,
   Space,
   Typography,
} from "antd";
import { useCallback, useEffect, useState } from "react";

import axios from "../../helper/axios_config";
import { useAppCtx } from "../../components/App/context";
import { EditOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;

export const Home = () => {
   const [loading, setLoading] = useState(false);
   const [editTitle, setEditTitle] = useState(false);
   const [form] = Form.useForm();
   const {
      value: { user, change },
   } = useAppCtx();

   const handleUpdateSetting = useCallback(
      async (values) => {
         try {
            setLoading(true);
            const {
               data: { data, error },
            } = await axios.put("/team/update", {
               id: user["team"]?.["id"],
               tieuDePhieuLuong: null,
               ...values,
            });
            if (error) notification.error({ message: error });
            else {
               change?.({ user: { ...user, team: data } });
               notification.success({
                  message: "Cập nhật cài đặt thành công!",
               });
            }
         } catch (error) {
            if (!error) return;
            const { message } = error;
            if (message) notification.error({ message });
         }
         setLoading(false);
      },
      [change, user]
   );

   useEffect(() => {
      if (!user || !user.team) return;
      form.setFieldsValue(user.team);
   }, [form, user]);

   return (
      <Content style={{ margin: "16px 0" }}>
         <Card title="Cài đặt chung">
            <Form layout="vertical" onFinish={handleUpdateSetting} form={form}>
               {editTitle ? (
                  <>
                     <Row>
                        <Col span={24}>
                           <Form.Item
                              label="Tiêu đề phiếu lương"
                              name="tieuDePhieuLuong"
                              style={{ marginBottom: 5 }}
                           >
                              <TextArea
                                 min={0}
                                 style={{ width: "100%" }}
                                 rows={4}
                              />
                           </Form.Item>
                        </Col>
                     </Row>
                     <Row justify="end" style={{ marginTop: 10 }}>
                        <Button
                           type="primary"
                           children="Huỷ"
                           onClick={() => setEditTitle(false)}
                        />
                     </Row>
                  </>
               ) : (
                  <Row>
                     <Col span={24}>
                        <Text>Tiêu đề phiếu lương:</Text>
                     </Col>
                     <Col span={24} style={{ marginBottom: 20 }}>
                        <Space>
                           <Text strong>{user?.team?.tieuDePhieuLuong}</Text>
                           <Button
                              type="text"
                              icon={<EditOutlined />}
                              onClick={() => setEditTitle(true)}
                           />
                        </Space>
                     </Col>
                  </Row>
               )}

               <Row gutter={20} style={{ marginTop: 30 }}>
                  <Col span={12}>
                     <Form.Item
                        label="Tổng số ngày làm việc của tháng"
                        name="soNgayCongCuaThang"
                        rules={[
                           {
                              required: true,
                              message: "Vui lòng nhập tổng số ngày làm việc!",
                           },
                        ]}
                     >
                        <InputNumber min={0} style={{ width: "100%" }} />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        label="Email nhận phản hồi"
                        name="emailFeedback"
                        rules={[
                           {
                              required: true,
                              message: "Vui lòng nhập email nhận phải hồi!",
                           },
                        ]}
                     >
                        <Input />
                     </Form.Item>
                  </Col>
               </Row>
               <Row justify="end">
                  <Button type="primary" htmlType="submit" loading={loading}>
                     Cập nhật
                  </Button>
               </Row>
            </Form>
         </Card>
      </Content>
   );
};
