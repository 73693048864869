import {
   Form,
   Input,
   Button,
   Typography,
   notification,
   Card,
   Row,
   Col,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useAppCtx } from "../components/App/context";
import loginImage from "../assets/images/login.jpeg";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import axios from "../helper/axios_config";
import { setCookie } from "../helper";

const { Title } = Typography;

export const Login = () => {
   const { value: appCtx } = useAppCtx();
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();

   const onFinish = useCallback(
      async (values) => {
         setLoading(true);

         try {
            const {
               data: { data, error },
            } = await axios.post("/login", values);
            if (error) notification.error({ message: error });
            else {
               notification.success({ message: "Đăng nhập thành công" });
               setCookie("salary_slip_token", data.token, 7);
               appCtx?.change?.(data);
               navigate("/");
            }
         } catch (error) {
            const { message } = error;
            message && notification.error({message:  message  });
         }
         setLoading(false);
      },
      [appCtx, navigate]
   );

   return (
      <div
         style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgb(237, 242, 247)",
         }}
      >
         <div className="container">
            <Card
               style={{
                  borderTopRightRadius: "0.5rem",
                  borderBottomRightRadius: "0.5rem",
               }}
               bodyStyle={{ padding: 0 }}
            >
               <Row>
                  <Col span={12}>
                     <div>
                        <img
                           src={`${loginImage}`}
                           style={{
                              width: "100%",
                              height: 500,
                              objectFit: "cover",
                              borderTopLeftRadius: "0.5rem",
                              borderBottomLeftRadius: "0.5rem",
                           }}
                        />
                     </div>
                  </Col>
                  <Col span={12}>
                     <Row
                        justify="space-around"
                        align="middle"
                        style={{ height: "100%", padding: 20 }}
                     >
                        <Col span={24}>
                           <Title level={3} style={{ textAlign: "center" }}>
                              Welcome Back!
                           </Title>
                           <Form
                              labelCol={{
                                 span: 8,
                              }}
                              initialValues={{
                                 remember: true,
                              }}
                              onFinish={onFinish}
                              autoComplete="off"
                              layout="vertical"
                           >
                              <Form.Item
                                 label="Email"
                                 name="email"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please enter your email!",
                                    },
                                 ]}
                              >
                                 <Input
                                    addonBefore={<UserOutlined />}
                                    placeholder="Enter your email"
                                 />
                              </Form.Item>

                              <Form.Item
                                 label="Password"
                                 name="password"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please enter your password!",
                                    },
                                 ]}
                              >
                                 <Input.Password
                                    addonBefore={<LockOutlined />}
                                    placeholder="Enter your password"
                                 />
                              </Form.Item>
                              <Form.Item
                                 wrapperCol={{
                                    span: 8,
                                 }}
                                 style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                 }}
                              >
                                 <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                 >
                                    Login
                                 </Button>
                              </Form.Item>
                           </Form>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Card>
         </div>
      </div>
   );
};
