import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, Modal, notification, Upload } from "antd";
import { useCallback, useState } from "react";
import axios from "../../helper/axios_config";
import { useSalarySlipContext } from "../SalarySlip/context";
const normFile = (e) => {
   if (Array.isArray(e)) {
      return e;
   }
   return e?.fileList;
};

export const UploadTimesheets = () => {
   const [openUpload, toggleUpload] = useState(false);
   const [loading, setLoading] = useState(false);
   const [form] = Form.useForm();

   const { refresh } = useSalarySlipContext();

   const handleUpload = useCallback(async () => {
      try {
         const { files } = await form.validateFields();
         const formData = new FormData();
         for (const f of files) {
            formData.append("timesheets", f["originFileObj"]);
         }
         try {
            setLoading(true);
            const {
               data: { error },
            } = await axios.post("/timesheet/upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            });

            if (error) notification.error({ message: error });
            else {
               notification.success({ message: "Thêm bảng lương thành công!" });
               toggleUpload?.((prev) => !prev);
               refresh?.();
            }
         } catch (error) {
            if (!error) return;
            const { message } = error;
            if (message) notification.error({ message });
         }
         setLoading(false);
      } catch (_) {}
   }, [form, refresh]);

   return (
      <>
         <Button
            key="upload"
            type="primary"
            children="Upload"
            onClick={() => toggleUpload((prev) => !prev)}
            icon={<UploadOutlined />}
         />
         <Modal
            title="Upload bảng lương"
            centered
            open={openUpload}
            onOk={handleUpload}
            onCancel={() => toggleUpload((prev) => !prev)}
            width={1000}
            confirmLoading={loading}
         >
            <Form form={form}>
               <Form.Item
                  name="files"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng upload file!",
                     },
                  ]}
               >
                  <Upload.Dragger multiple beforeUpload={() => false}>
                     <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                     </p>
                     <p className="ant-upload-text">
                        Nhấp hoặc kéo ảnh vào khu vực này để tải lên
                     </p>
                  </Upload.Dragger>
               </Form.Item>
            </Form>
         </Modal>
      </>
   );
};
