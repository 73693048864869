import { Card, Layout, notification, Input, PageHeader } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";

import { SalarySlips } from "../../components/SalarySlip";
import { SalarySlipProvider } from "../../components/SalarySlip/context";
import { Timesheets } from "../../components/Timesheets";
import { SendTimeSheet } from "../../components/Timesheets/Send";
import { UploadTimesheets } from "../../components/Timesheets/Upload";
import axios from "../../helper/axios_config";

const { Search } = Input;
const { Content } = Layout;

export const TimesheetPage = () => {
   const [loading, setLoading] = useState(true);
   const [salarySlips, setSalarySlip] = useState([]);
   const [filter, setFilter] = useState({
      paged: 1,
      limit: 20,
      search: "",
   });
   const timeoutRef = useRef(null);

   const changeFilter = useCallback(
      (value) => {
         setFilter((prev) => {
            if (
               value.paged !== prev.paged ||
               value.limit !== prev.limit ||
               value.search !== prev.search
            ) {
               return {
                  paged: value.paged ? value.paged : prev.paged,
                  limit: value.limit ? value.limit : prev.limit,
                  search: value.search != null ? value.search : prev.search,
               };
            }
            return prev;
         });
      },
      [setFilter]
   );

   const searchChange = useCallback((e) => {
      const {
         target: { value: search },
      } = e;
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
         setFilter((prev) => ({ ...prev, paged: 1, search }));
      }, 500);
   }, []);

   const getSalarySlip = useCallback(() => {
      setLoading(true);
      axios
         .get("/salary_slip/salary_slips", { params: filter })
         .then((result) => {
            const {
               data: { data, error },
            } = result;
            if (error) notification.error({ message: error });
            else setSalarySlip(data);
         })
         .then((error) => {
            if (!error) return;
            const { message } = error;
            if (message) notification.error({ message });
         });
      setLoading(false);
   }, [filter]);

   useEffect(() => {
      getSalarySlip();
   }, [filter, getSalarySlip]);

   return (
      <SalarySlipProvider refresh={getSalarySlip}>
         <PageHeader
            extra={[
               <UploadTimesheets key="upload-timesheets" />,
               <SendTimeSheet
                  isDisable={false}
                  btnName="Gửi tất cả"
                  isSendAll={true}
                  timeSheet={{}}
                  key="send-all"
               />,
            ]}
         />

         <Card style={{ marginBottom: 20 }}>
            <Search
               placeholder="Enter search"
               allowClear
               onChange={searchChange}
            />
         </Card>

         <Content>
            <Timesheets
               loading={loading}
               value={salarySlips}
               filter={filter}
               onChangeFilter={changeFilter}
            />
         </Content>
      </SalarySlipProvider>
   );
};
